import {withTranslation} from 'react-i18next';
import * as Icon from 'react-bootstrap-icons';
import {FormattedMessage, IntlProvider} from 'react-intl';
import parse from "html-react-parser";

const copyrightMessages = {
    copyrightMessage: "© 2013-{ts, date, ::yyyy} ",
}

function Footer({t, i18n}) {

    return (
        <footer>
            <div>
                <hr/>
                <div className="text-center">
                    <div className="site-header-tagline">
                        {parse(t('messages.footer.tagline'))}
                    </div>
                    <div className={"m-2"}>
                        <a className="fab-item"
                           href="https://www.facebook.com/labeaute.berlin"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Facebook className="facebook-icon me-3" size={"24"}/>
                        </a>
                        <a className="fab-item"
                           href="https://www.instagram.com/labeaute.berlin"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Instagram className="instagram-icon me-3" size={"24"}/>
                        </a>
                        <a className="fab-item"
                           href="https://twitter.com/LaBeauteBerlin"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <Icon.Twitter className="twitter-icon" size={"24"}/>
                        </a>
                    </div>
                    <div className={"mb-3"}>
                        <IntlProvider messages={copyrightMessages} locale={i18n.language} defaultLocale="de">
                            <FormattedMessage
                                id="copyrightMessage"
                                defaultMessage="© 2013-{ts, date, ::yyyy} "
                                values={{ts: Date.now()}}
                            />
                        </IntlProvider>
                        <a className={"a-link"} href="https://la-beaute.de">
                            {t('messages.page.contact.web.text')}
                        </a>
                    </div>
                </div>
            </div>

            {/* icon gradient */}
            {/*<svg aria-hidden="true" focusable="false" style="width:0;height:0;position:absolute;">*/}
            {/*    <linearGradient id="gradient-horizontal">*/}
            {/*        <stop offset="0%" stopColor="var(--color-stop-1)"/>*/}
            {/*        <stop offset="50%" stopColor="var(--color-stop-2)"/>*/}
            {/*        <stop offset="100%" stopColor="var(--color-stop-3)"/>*/}
            {/*    </linearGradient>*/}
            {/*    <linearGradient id="gradient-vertical" x2="0" y2="1">*/}
            {/*        <stop offset="0%" stopColor="var(--color-stop-1)"/>*/}
            {/*        <stop offset="100%" stopColor="var(--color-stop-3)"/>*/}
            {/*    </linearGradient>*/}
            {/*</svg>*/}
        </footer>
    );
}

export default withTranslation()(Footer);