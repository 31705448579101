import {withTranslation} from "react-i18next";
import Product from "./Product";
import {Row} from "react-bootstrap";

function Products(props) {
    const products = props.products;

    return (
        <Row className="justify-content-center">
                {products.map((product) =>
                    <Product product={product}/>
                )}
        </Row>
    );
}

export default withTranslation()(Products);