import {Trans, withTranslation} from "react-i18next";
import * as Icon from "react-bootstrap-icons";
import {Col, Container, Row} from "react-bootstrap";

const Contact = ({t}) => {
    return (
        <>
            <section>
                <Container className="content-text text-center mt-3">
                    <h1>
                        <Trans i18nKey="messages.page.contact.title"/>
                    </h1>
                    <div>
                        <Trans i18nKey="messages.page.contact.text"/>
                    </div>
                </Container>
            </section>
            <section>
                <Container className={"content-text mt-3"}>
                    <Row>
                        <Col className={"col-xs-6 col-md-6"}>
                            <h4 className="mt-3">
                                <Icon.House className="text-danger" size={"24"}/>
                                &nbsp;
                                {t('messages.page.contact.address.title')}
                            </h4>
                            <div>
                                <a className={"a-link"} href="https://goo.gl/maps/dYfmR2zKuj9KZnSEA"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                >
                                    <Trans i18nKey="messages.page.contact.address.text"/>
                                </a>
                            </div>
                        </Col>
                        <Col className="col-xs-6 col-md-6">
                            <h4 className="mt-3">
                                <Icon.Phone className="text-warning" size={"24"}/>
                                &nbsp;
                                {t('messages.page.contact.phone.title')}
                            </h4>
                            <div>
                                <a className={"a-link"} href="tel:03089409834">
                                    <Trans i18nKey="messages.page.contact.phone.work"/>
                                </a>
                            </div>
                            <div>
                                <a className={"a-link"} href="tel:015730053070">
                                    <Trans i18nKey="messages.page.contact.phone.mobile"/>
                                </a>
                            </div>
                            <h4 className="mt-3">
                                <Icon.Envelope className="text-info" size={"24"}/>
                                &nbsp;
                                {t('messages.page.contact.email.title')}
                            </h4>
                            <div>
                                <a className={"a-link"}
                                   href={"mailto:" + t('messages.page.contact.email.to') + "?Subject=" + t('messages.page.contact.email.subject')}
                                >
                                    <Trans i18nKey="messages.page.contact.email.to"/>
                                </a>
                            </div>
                            <h4 className="mt-3">
                                <Icon.Globe className="text-success" size={"24"}/>
                                &nbsp;
                                {t('messages.page.contact.web.title')}
                            </h4>
                            <div>
                                <a className={"a-link"} href="https://la-beaute.de">
                                    <Trans i18nKey="messages.page.contact.web.text"/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default withTranslation()(Contact);