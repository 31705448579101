import {withTranslation} from "react-i18next";
import {Col} from "react-bootstrap";

function Product(props) {
    const product = props.product;

    return (
        <Col className="col-lg-2 col-md-3 col-sm-4 col-6">
            <a id={product.slug_text + "-tab"}
               className={"nav-link"}
               href={"/treatment/#" + product.slug_text}
               role="tab"
            >
                <img id={product.slug_text}
                     className={"img-thumbnail rounded-circle img-5"}
                     src={"/images/treatment/" + product.slug_text + ".webp"}
                     alt={product.title}/>
            </a>
            <div className={"card-body"}>
                <h3 className={"card-title"}>
                    {product.title}
                </h3>
                <p className={"card-text"}>
                    {product.description}
                </p>
            </div>
        </Col>
    );
}

export default withTranslation()(Product);